export const LIFF_ID = process.env.REACT_APP_LIFF_ID as string;

export const BASE_URL = process.env.REACT_APP_API_ENDPOINT;

export const GA_TRACKING_ID = process.env.REACT_APP_GTM_ID as string;

export const API_RESPONSE_STATUS = {
  SUCCEEDED: 'succeeded',
  FAILED: 'failed'
};

export const INDEFINITE_PERIOD = '無期限';
