import React from 'react';
import { Container, Stack } from 'react-bootstrap';
import liff from '@line/liff/dist/lib';
import CommonHead from '../components/CommonHead';

const SessionError: React.FC = () => {
  return (
    <>
      <CommonHead title="セッションの期限切れ" />
      <div className="d-flex vh-100 wrapper">
        <Container className="my-auto">
          <Stack gap={3}>
            <h4>セッションの期限切れ</h4>
            <p>アプリを再起動してください。</p>
          </Stack>
        </Container>
        <div className="bottom-area p-3">
          <button
            className="d-block btn btn--custom btn--primary btn--rounded p-3 w-100"
            onClick={() => liff.closeWindow()}
          >
            アプリを閉じる
          </button>
        </div>
      </div>
    </>
  );
};

export default SessionError;
