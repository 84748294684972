import React from 'react';
import { Container, Form, Stack } from 'react-bootstrap';
import qrCodeReader from '../assets/icons/qrCodeReader.svg';

type UseVoucherStoreInputType = {
  isError: boolean;
  errorMsg: string;
  handleSubmitStoreCode: VoidFunction;
  handleQrReading: VoidFunction;
  isLoading: boolean;
};

const UseVoucherStoreInput = React.forwardRef<
  HTMLInputElement,
  UseVoucherStoreInputType
>(function displayUseVoucherStoreInput({ ...props }, ref) {
  const {
    isError,
    errorMsg,
    handleSubmitStoreCode,
    handleQrReading,
    isLoading
  } = props;
  return (
    <div className="wrapper pt-0">
      {isError && (
        <div className="error-box">
          <span>{errorMsg}</span>
        </div>
      )}
      <Container>
        <Stack gap={2}>
          <div className="pt-2">
            <p>フォームに店舗コードを入力してご利用ください。</p>
            <p className="text--gray">
              ※「店舗コード」が不明な場合、店内掲示物をご確認いただくか、近くのスタッフまでお声がけください。
            </p>
          </div>

          <div className="form-store">
            <Form.Label htmlFor="storecode">店舗コード</Form.Label>
            <Form.Control
              type="text"
              id="storecode"
              placeholder="0000"
              aria-describedby="storeCode"
              ref={ref}
            />
          </div>
          <button
            onClick={handleSubmitStoreCode}
            className="d-block btn btn--custom btn--primary btn--rounded p-3 mx-3 mt-4"
            aria-disabled={isLoading}
            disabled={isLoading}
          >
            {isLoading
              ? '店舗コードを確認中…'
              : 'ギフト／プリペイド利用にすすむ'}
          </button>
        </Stack>
      </Container>
      <div className="bottom-area position-absolute mb-4 bg--transparent text-center">
        <button
          className="d-inline-block btn btn--custom btn--link btn--rounded p-3 mx-3"
          onClick={handleQrReading}
        >
          <img src={qrCodeReader} alt="QRコード読み取り" />
          <span className="ms-3 fw-normal">QRコード読み取り</span>
        </button>
      </div>
    </div>
  );
});

export default UseVoucherStoreInput;
