import React from 'react';
import { Card, Stack } from 'react-bootstrap';
import { VoucherUsageItemType } from '../types/VoucherType';

const VoucherUsageItem: React.FC<VoucherUsageItemType> = (props) => {
  const {
    shopName: shop_name,
    usedDate: used_date,
    usedTime: used_time,
    transId,
    amount,
    isCharged,
    as
  } = props;

  return (
    <>
      {as === 'useConfirm' || as === 'useVoucherCompleted' ? (
        <Card className="usage-card pt-1">
          <Card.Body>
            <Card.Title className="usage-card__title mb-2">
              {shop_name}
            </Card.Title>
            <Stack direction="vertical">
              <div className="usage-card__info">
                {as === 'useConfirm' && <span>お支払額</span>}
                {as === 'useVoucherCompleted' && (
                  <>
                    <span>
                      {used_date} {used_time}
                    </span>
                    {<span>取引番号：{transId}</span>}
                  </>
                )}
              </div>
              <div className={'usage-card__amount text-center'}>
                <span className="usage-card__amount-value use ms-4 me-2 fw-bold">
                  {amount.toLocaleString()}
                </span>
                <span className="usage-card__amount-unit use fw-bold">円</span>
              </div>
            </Stack>
          </Card.Body>
        </Card>
      ) : (
        <Card className="usage-card">
          <Card.Body>
            <Card.Title className="usage-card__title">{shop_name}</Card.Title>
            <Stack direction="horizontal">
              <div className="usage-card__info">
                <span>{used_date}</span>
                {transId && <span>取引番号：{transId}</span>}
              </div>
              <div
                className={`usage-card__amount ${isCharged ? 'charged' : ''}`}
              >
                <span className="usage-card__amount-value fs-1 ms-4 me-2 fw-bold">
                  {`${isCharged ? '+' : ''}${amount.toLocaleString()}`}
                </span>
                <span className="usage-card__amount-unit">円</span>
              </div>
            </Stack>
          </Card.Body>
        </Card>
      )}
    </>
  );
};

export default VoucherUsageItem;
