import React from 'react';
import { Container, Stack } from 'react-bootstrap';
import VoucherUsageItem from './VoucherUsageItem';

type UseVoucherConfirmType = {
  shopName: string;
  amount: number;
  voucherId: string;
  handleSubmit: VoidFunction;
  handleCancel: VoidFunction;
  isLoading: boolean;
};

const UseVoucherConfirm: React.FC<UseVoucherConfirmType> = (props) => {
  const { shopName, amount, handleSubmit, handleCancel, isLoading } = props;
  return (
    <>
      <div className="wrapper bg--gray">
        <Container>
          <div className="confirm-dialog mb-4">
            <p className="danger">まだお支払いは完了していません</p>
            <p className="footnote">※お釣りは出ませんのでご了承ください</p>
          </div>

          <VoucherUsageItem
            shopName={shopName}
            usedDate=""
            usedTime=""
            transId=""
            amount={amount}
            as="useConfirm"
            isCharged={false}
          />
        </Container>
        <div className="bottom-area mb-4 bg--transparent">
          <Stack gap={3} className="text-center">
            <p className="fw-bold">
              こちらの画面をお店の人に見せながら
              <br />
              「支払う」ボタンを押してください。
            </p>
            <button
              onClick={handleSubmit}
              className="d-block btn btn--custom btn--primary btn--rounded p-3 mx-3"
              aria-disabled={isLoading}
              disabled={isLoading}
            >
              {isLoading ? '決済中…' : '支払う'}
            </button>
            <button
              onClick={handleCancel}
              className="btn btn--custom btn--link mx-3"
            >
              もどる
            </button>
          </Stack>
        </div>
      </div>
    </>
  );
};

export default UseVoucherConfirm;
