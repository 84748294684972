import React from 'react';
import { Card, Container, Form, Stack } from 'react-bootstrap';
import { Link } from 'react-router-dom';

type UseVoucherAmountInputType = {
  isError: boolean;
  errorMsg: string;
  handleSubmitAmount: VoidFunction;
  handleQrReading: VoidFunction;
  voucherId: string;
  availableAmount?: number | null;
  shop_name: string;
};

const UseVoucherAmountInput = React.forwardRef<
  HTMLInputElement,
  UseVoucherAmountInputType
>(function displayUseVoucherAmountInput({ ...props }, ref) {
  const {
    isError,
    errorMsg,
    handleSubmitAmount,
    handleQrReading,
    voucherId,
    availableAmount,
    shop_name
  } = props;
  return (
    <div className="wrapper pt-0 bg--gray">
      {isError && (
        <div className="error-box">
          <span>{errorMsg}</span>
        </div>
      )}
      <Container>
        <Stack className="pt-4" gap={3}>
          <div className="shop-info">
            <span>ご利用店舗</span>
            <h4 className="shop-info__name">{shop_name}</h4>
          </div>
          <Card className="use-voucher">
            <Card.Body>
              <Stack direction="vertical">
                {availableAmount && (
                  <p className="use-voucher__deposit">
                    ご利用可能残高
                    <span>{(availableAmount || 0).toLocaleString()}円</span>
                  </p>
                )}
                <div
                  className={`use-voucher__amount ${isError ? 'error' : ''}`}
                >
                  <Form.Control
                    type="number"
                    id="use_amount"
                    aria-describedby="use_amount"
                    ref={ref}
                  />
                </div>
              </Stack>
            </Card.Body>
          </Card>
          <button
            onClick={handleQrReading}
            className="btn btn--custom btn--link mx-3 fw-normal"
          >
            再読み取りはこちら
          </button>
        </Stack>
      </Container>
      <div className="bottom-area mb-4 bg--transparent">
        <Stack gap={3}>
          <button
            onClick={handleSubmitAmount}
            className="d-block btn btn--custom btn--primary btn--rounded p-3 mx-3"
          >
            お支払い内容を確認する
          </button>
          <Link
            to={`/vouchers/${voucherId}`}
            className="btn btn--custom btn--link mx-3"
          >
            もどる
          </Link>
        </Stack>
      </div>
    </div>
  );
});

export default UseVoucherAmountInput;
