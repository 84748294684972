import liff from '@line/liff/dist/lib';
import axios, { AxiosRequestHeaders } from 'axios';
import { BASE_URL } from '../lib';
import { RegisterUTMType } from '../types/UserType';

interface RequestType {
  url: string;
  method: 'GET' | 'POST' | 'DELETE' | 'PATCH';
  hasAccessToken?: boolean;
  data?: object;
  params?: object;
  errLabel: string;
}

const sendRequest = ({
  url,
  method,
  hasAccessToken,
  data,
  params,
  errLabel
}: RequestType) => {
  const idToken = liff.getIDToken() as string;
  const headers: AxiosRequestHeaders = {
    'Content-Type': 'application/json; charset=UTF-8',
    'Token-Id': idToken
  };

  if (hasAccessToken) {
    const accessToken = liff.getAccessToken() as string;
    headers['Access-Token'] = accessToken;
  }

  return axios({
    url: `${BASE_URL}${url}`,
    method: method,
    headers: headers,
    data: data,
    params: params
  })
    .then((res) => res.data)
    .catch((err) => {
      console.log(`${errLabel}: `, err.response);

      // TODO: Check whether cx should be moved to /error page on api error 400 or 500.
      // if ([500].includes(err.response.status)) {
      //   window.location.replace(process.env.PUBLIC_URL + '/error');
      // }

      // if ([401].includes(err.response.status)) {
      //   window.location.replace(process.env.PUBLIC_URL + '/vouchers');
      // }

      const error = err.response.data?.errors;
      if (error.includes('IdToken expired.')) {
        localStorage.clear();
        window.location.replace(process.env.PUBLIC_URL + '/expired-idtoken');
      }

      // TODO: 審査提出後、対応
      // return err.response.data;
    });
};

export const registerAccount = (utm_data?: RegisterUTMType) => {
  // TODO: Register User with GA?
  return sendRequest({
    url: '/users',
    method: 'POST',
    data: utm_data,
    errLabel: 'ErrorRegisteringAccount'
  });
};

export const getNewVoucher = (voucherId: string) => {
  return sendRequest({
    url: `/users/current/giftcards/new?card_no=${voucherId}`,
    method: 'GET',
    data: {
      card_no: voucherId
    },
    errLabel: `ErrorGetNewVoucher voucherId:${voucherId}`
  });
};

export const activateVoucher = (voucherId: string, pin: string) => {
  return sendRequest({
    url: '/users/current/giftcards',
    method: 'POST',
    data: {
      card_no: voucherId,
      pin
    },
    hasAccessToken: true,
    errLabel: `ErrorActivateVoucher voucherId:${voucherId}`
  });
};

export const getVouchers = () => {
  return sendRequest({
    url: '/users/current/giftcards',
    method: 'GET',
    errLabel: 'ErrorGetVouchers'
  });
};

export const getRegisteredVoucherDetailAndUsages = (id: string) => {
  return sendRequest({
    url: `/users/current/giftcards/${id}`,
    method: 'GET',
    errLabel: `ErrorGetRegisteredVoucherDetailById: ${id}`
  });
};

export const getStoreAndCheckAvailablity = (
  voucherId: string,
  code: string
) => {
  return sendRequest({
    url: `/users/current/giftcards/${voucherId}/store?code=${code}`,
    method: 'GET',
    data: { code },
    errLabel: `ErrorGetStoreAndCheckAvailablity voucherId:${voucherId}, code:${code}`
  });
};

export const spendVoucher = (
  voucherId: string,
  usage_amount: number,
  store_code: string
) => {
  return sendRequest({
    url: `/users/current/giftcards/${voucherId}/balance`,
    method: 'PATCH',
    data: {
      usage_amount,
      store_code
    },
    hasAccessToken: true,
    errLabel: `ErrorActivateVoucher voucherId:${voucherId} store code:${store_code}`
  });
};

export const getAllNotifications = () => {
  return sendRequest({
    url: '/notifications',
    method: 'GET',
    errLabel: 'ErrorGetNotifications'
  });
};

export const getNotificationById = (id: string) => {
  return sendRequest({
    url: `/notifications/${id}`,
    method: 'GET',
    errLabel: `ErrorGetNotification id:${id}`
  });
};
