import React, { useRef, useState } from 'react';
import { Container, Form, Stack } from 'react-bootstrap';
import RegisterVoucherConfirm from '../components/RegisterVoucherConfirm';
import qrCodeReader from '../assets/icons/qrCodeReader.svg';
import CommonHead from '../components/CommonHead';
import liff from '@line/liff/dist/lib';
import RegisterVoucherCompleted from '../components/RegisterVoucherCompleted';
import { activateVoucher, getNewVoucher } from '../api';
import { API_RESPONSE_STATUS } from '../lib';

type VerifyVoucherType = {
  giftcode: string;
};

type RegisterVoucherType = VerifyVoucherType & {
  pin: string;
  name: string;
  imgSrc: string;
};

const initialRegisterVoucherValue: RegisterVoucherType = {
  giftcode: '',
  pin: '',
  name: '',
  imgSrc: ''
};

const RegisterVoucher: React.FC = () => {
  const [isError, setIsError] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string>('');
  const giftcodeInputRef = useRef<HTMLInputElement>(null);
  const pinInputRef = useRef<HTMLInputElement>(null);

  const [voucherState, setVoucherState] = useState<RegisterVoucherType>(
    initialRegisterVoucherValue
  );

  const [currentStep, setCurrentStep] = useState<
    'inputVoucherCode' | 'readingQr' | 'confirm' | 'completed'
  >('inputVoucherCode');

  const handleQrReading = () => {
    setCurrentStep('readingQr');

    liff
      .scanCodeV2()
      .then((result) => {
        if (result.value === null) {
          setCurrentStep('inputVoucherCode');
        } else {
          const readStr = result.value ?? '';
          // separate giftcode/pin from one string read by QR
          // QR code value consists the giftcode with 16 digits, and pincode with 4-6 digits.
          const giftcode = readStr.slice(0, 16);
          const pin = readStr.slice(16, readStr.length) ?? '';
          // fetch giftcode name
          getNewVoucher(giftcode).then((res) => {
            if (res) {
              // incase of error on verification, set the error msg as 入力された番号のギフト／プリペイドが見つかりませんでした
              // setVoucherState as giftcode/pin and transit to confirm
              // FIXME: Sort out the logic
              if (res.status === API_RESPONSE_STATUS.SUCCEEDED) {
                setVoucherState((prev) => ({
                  ...prev,
                  giftcode,
                  pin,
                  name: res.data.name,
                  imgSrc: res.data.image_file_name?.url ?? ''
                }));
                setCurrentStep('confirm');
              } else {
                setIsError(true);
                setErrorMsg(
                  '入力された番号のギフト／プリペイドが見つかりませんでした'
                );
                setCurrentStep('inputVoucherCode');
              }
            } else {
              setIsError(true);
              setErrorMsg(
                '入力された番号のギフト／プリペイドが見つかりませんでした'
              );
              setCurrentStep('inputVoucherCode');
            }
          });
        }
      })
      .catch((err) => {
        console.log('UNABLE TO USE QR SCANNER', err);
        setCurrentStep('inputVoucherCode');
      });
  };

  const handleSubmitInputs = () => {
    setIsError(false);

    // verify whether giftcode and pin are filled
    const giftcode = giftcodeInputRef.current?.value ?? '';
    const pin = pinInputRef.current?.value ?? '';

    if (giftcode !== '' && pin !== '') {
      setIsLoading(true);
      // fetch giftcode name
      getNewVoucher(giftcode).then((res) => {
        if (res) {
          // setVoucherState as giftcode/pin and move to confirm
          setVoucherState((prev) => ({
            ...prev,
            giftcode,
            pin,
            name: res.data.name,
            imgSrc: res.data.image_file_name?.url ?? ''
          }));
          setIsLoading(false);
          setCurrentStep('confirm');
        } else {
          setIsError(true);
          setIsLoading(false);
          setErrorMsg(
            '入力された番号のギフト／プリペイドが見つかりませんでした'
          );
        }
      });
    } else {
      setIsError(true);
      setErrorMsg('ギフトコードとPINを入力してください');
    }
  };

  const handleSubmitConfirmation = () => {
    // post params to register voucher
    setIsError(false);
    setIsLoading(true);
    activateVoucher(voucherState.giftcode, voucherState.pin).then((res) => {
      setIsLoading(false);
      if (res) {
        setCurrentStep('completed');
      } else {
        setIsError(true);
        setIsLoading(false);
        setErrorMsg('ギフトコード または PIN が違います');
        setCurrentStep('inputVoucherCode');
      }
    });
  };

  const handleCancelSubmitVoucher = () => {
    setCurrentStep('inputVoucherCode');
  };

  return (
    <>
      <CommonHead title="ギフト／プリペイド登録" />
      <div
        className={`${currentStep !== 'completed' && 'wrapper'} ${
          isError ? 'pt-0' : ''
        }`}
      >
        {currentStep === 'readingQr' && <div></div>}
        {currentStep === 'inputVoucherCode' && (
          <>
            {isError && (
              <div className="error-box">
                <span>{errorMsg}</span>
              </div>
            )}
            <Container>
              <Stack className="register-voucher" gap={3}>
                <div>
                  <Form.Label htmlFor="giftcode">ギフトコード</Form.Label>
                  <Form.Control
                    type="tel"
                    id="giftcode"
                    placeholder="0000000000000000"
                    aria-describedby="giftcode"
                    ref={giftcodeInputRef}
                  />
                </div>
                <div>
                  <Form.Label htmlFor="pin">PIN</Form.Label>
                  <Form.Control
                    type="text"
                    id="pin"
                    aria-describedby="pin"
                    placeholder="0000"
                    ref={pinInputRef}
                  />
                </div>
                <button
                  onClick={handleSubmitInputs}
                  className="d-block btn btn--custom btn--primary btn--rounded p-3 mx-3 mt-4"
                  aria-disabled={isLoading}
                  disabled={isLoading}
                >
                  {isLoading ? '確認中…' : '確認する'}
                </button>
              </Stack>
            </Container>
            <div className="bottom-area position-absolute text-center pb-4">
              <button
                onClick={handleQrReading}
                className="d-inline-block btn btn--custom btn--link btn--rounded p-3 mx-3"
              >
                <img src={qrCodeReader} alt="QRコード読み取り" />
                <span className="ms-3  fw-normal">QRコード読み取り</span>
              </button>
            </div>
          </>
        )}
        {currentStep === 'confirm' && (
          <>
            <Container>
              <RegisterVoucherConfirm
                name={voucherState.name}
                imgSrc={voucherState.imgSrc}
              />
            </Container>
            <div className="bottom-area pb-4">
              <Stack gap={3}>
                <button
                  onClick={handleSubmitConfirmation}
                  className="d-block btn btn--custom btn--primary btn--rounded p-3 mx-3"
                  aria-disabled={isLoading}
                  disabled={isLoading}
                >
                  {isLoading ? '登録中…' : '登録する'}
                </button>
                <button
                  onClick={handleCancelSubmitVoucher}
                  className="btn btn--custom btn--link mx-3"
                >
                  もどる
                </button>
              </Stack>
            </div>
          </>
        )}
        {currentStep === 'completed' && <RegisterVoucherCompleted />}
      </div>
    </>
  );
};

export default RegisterVoucher;
