import React from 'react';
import { Container, Stack } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import CommonHead from '../components/CommonHead';

const Error: React.FC = () => {
  return (
    <>
      <CommonHead title="エラー" />
      <div className="d-flex vh-100 wrapper">
        <Container className="my-auto">
          <Stack gap={3}>
            <h4>エラーが発生しました</h4>
            <p>
              お手数ですが、ギフト／プリペイド一覧画面から手続きをやり直してください。
            </p>
          </Stack>
        </Container>
        <div className="bottom-area">
          <Link
            to="/vouchers"
            className="d-block btn btn--custom btn--primary btn--rounded p-3 m-3"
          >
            ギフト／プリペイド一覧へ
          </Link>
        </div>
      </div>
    </>
  );
};

export default Error;
