import liff from '@line/liff/dist/lib';

export const openWindow = (url: string, external?: boolean) => {
  if (url) {
    liff.openWindow({
      url: url,
      external: external ?? false
    });
  }
};
