import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { getAllNotifications } from '../api';
import CommonHead from '../components/CommonHead';
import Footer from '../components/Footer';
import NewsItems from '../components/NewsItems';
import { API_RESPONSE_STATUS } from '../lib';
import { NewsItemType } from '../types/NewsType';

const NewsList: React.FC = () => {
  const [notifications, setNotifications] = useState<Array<NewsItemType>>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  useEffect(() => {
    getAllNotifications()
      .then((res) => {
        if (res.status === API_RESPONSE_STATUS.SUCCEEDED) {
          setNotifications(res.data.notifications);
        }
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  }, []);

  return (
    <>
      <CommonHead title="お知らせ一覧" />
      <div className="wrapper">
        <Container>
          {!isLoading && (
            <>
              {notifications.length > 0 ? (
                <NewsItems news={notifications} />
              ) : (
                <p className="pt-1">新しいお知らせはありません。</p>
              )}
            </>
          )}
        </Container>
        <div className="bottom-area">
          <Footer />
        </div>
      </div>
    </>
  );
};

export default NewsList;
