import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import liff from '@line/liff/dist/lib';
import { GA_TRACKING_ID } from '.';

declare global {
  interface Window {
    gtag?: (
      key: string,
      trackingId: string,
      config: {
        page_path: string;
        user_id: string;
      }
    ) => void;
  }
}

const GoogleAnalytics: React.FC = () => {
  const location = useLocation();

  useEffect(() => {
    if (!window.gtag) return;
    if (!GA_TRACKING_ID) {
      console.log(
        'Tracking not enabled, as `trackingId` was not given and there is no `GA_TRACKING_ID`.'
      );
      return;
    }

    liff.getProfile().then((user) => {
      window.gtag?.('config', GA_TRACKING_ID as string, {
        page_path: location.pathname,
        user_id: user.userId
      });
    });
  }, [location]);

  return null;
};

export default GoogleAnalytics;
