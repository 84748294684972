import React from 'react';
import { Container } from 'react-bootstrap';

const Page404: React.FC = () => {
  return (
    <>
      <div className="wrapper">
        <Container>
          <p>404 Not Found</p>
        </Container>
      </div>
    </>
  );
};

export default Page404;
