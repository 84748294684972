import React from 'react';
import { Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';

type NewsItemType = {
  id: string;
  subject: string;
  postedDate: string;
  category: string;
};

const NewsItem: React.FC<NewsItemType> = (props) => {
  const { id, subject, postedDate, category } = props;
  return (
    <Link to={`/news/${id}`}>
      <Card className="news-item p-3">
        <div className="news-item__header">
          <div className="news-item__header__title">
            <span>{subject}</span>
          </div>
          <div className="news-item__header__date">
            <span>{postedDate}</span>
          </div>
        </div>
        <Card.Text className="mt-1 news-item__category">{category}</Card.Text>
      </Card>
    </Link>
  );
};

export default NewsItem;
