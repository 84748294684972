import React from 'react';
import { Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Completed from './Completed';
import VoucherUsageItem from './VoucherUsageItem';

type UseVoucherCompletedType = {
  shopName: string;
  usedDate: string;
  usedTime: string;
  transId: string;
  amount: number;
};

const UseVoucherCompleted: React.FC<UseVoucherCompletedType> = (props) => {
  const { shopName, usedDate, usedTime, transId, amount } = props;
  return (
    <>
      <div className="vh-100 wrapper bg--gray">
        <Container>
          <div>
            <VoucherUsageItem
              shopName={shopName}
              usedDate={usedDate}
              usedTime={usedTime}
              transId={transId}
              amount={amount}
              as="useVoucherCompleted"
              isCharged={false}
            />
          </div>
          <div className="centering">
            <Completed message="ご利用ありがとうございました！" />
          </div>
        </Container>
        <div className="bottom-area">
          <Link
            to="/vouchers"
            className="d-block btn btn--custom btn--primary btn--rounded p-3 m-3"
          >
            商品券一覧へ
          </Link>
        </div>
      </div>
    </>
  );
};

export default UseVoucherCompleted;
