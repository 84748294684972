import React from 'react';
import { Card, Stack } from 'react-bootstrap';
import sampleImage from '../assets/images/sample_voucher.png';

type RegisterVoucherConfirmType = {
  name: string;
  imgSrc?: string | null;
};

const RegisterVoucherConfirm: React.FC<RegisterVoucherConfirmType> = (
  props
) => {
  const { name, imgSrc } = props;
  return (
    <Stack gap={0} className="text-center">
      <div className="confirm-dialog">
        <p>この商品を登録しますか？</p>
      </div>
      <Card className="voucher-card">
        <Card.Body>
          <Stack
            direction="horizontal"
            gap={3}
            className="align-items-center justify-content-between"
          >
            <div className="voucher-card__image">
              <img src={imgSrc || sampleImage} />
            </div>
            <div className="flex-grow-1">
              <div className="voucher-card__title">{name}</div>
            </div>
          </Stack>
        </Card.Body>
      </Card>
    </Stack>
  );
};

export default RegisterVoucherConfirm;
