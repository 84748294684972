import React, { useLayoutEffect } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom';
import GoogleAnalytics from './lib/GoogleAnalytics';
import Error from './pages/Error';
import NewsDetail from './pages/NewsDetail';
import NewsList from './pages/NewsList';
import Page404 from './pages/Page404';
import RegisterVoucher from './pages/RegisterVoucher';
import SessionError from './pages/SessionError';
import UseVoucher from './pages/UseVoucher';
import VoucherDetail from './pages/VoucherDetail';
import VoucherList from './pages/VoucherList';
import VoucherUsagesList from './pages/VoucherUsageList';

const ScrollToTop: React.FC = ({ children }) => {
  const location = useLocation();
  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);
  return <>{children}</>;
};

const App: React.FC = () => {
  return (
    <HelmetProvider>
      <BrowserRouter basename={process.env.PUBLIC_URL as string}>
        <ScrollToTop>
          <Routes>
            <Route path="/" element={<Page404 />} />
            <Route path="/vouchers" element={<VoucherList />} />
            <Route path="/vouchers/register" element={<RegisterVoucher />} />

            <Route path="/vouchers/:id" element={<VoucherDetail />} />
            <Route path="/vouchers/:id/use" element={<UseVoucher />} />
            <Route
              path="/vouchers/:id/usages"
              element={<VoucherUsagesList />}
            />
            <Route path="/news" element={<NewsList />} />
            <Route path="/news/:id" element={<NewsDetail />} />
            <Route path="/error" element={<Error />} />
            <Route path="/expired-idtoken" element={<SessionError />} />
            <Route path="/*" element={<Page404 />} />
          </Routes>
        </ScrollToTop>
        <GoogleAnalytics />
      </BrowserRouter>
    </HelmetProvider>
  );
};

export default App;
