import React, { useEffect, useState } from 'react';
import { Container, Stack } from 'react-bootstrap';
import { Link, useSearchParams } from 'react-router-dom';
import { getVouchers, registerAccount } from '../api';
import CommonHead from '../components/CommonHead';
import EmptyVouchers from '../components/EmptyVouchers';
import Footer from '../components/Footer';
import VoucherItem from '../components/VoucherItem';

import { API_RESPONSE_STATUS, INDEFINITE_PERIOD } from '../lib';

type VoucherStateType = {
  available_stores_url: string | null;
  balance: number;
  card_no: string;
  expired_at: string;
  giftcard_id: string;
  image_file_name: string | null;
  name: string;
};

const VoucherList: React.FC = () => {
  const [searchParams] = useSearchParams();
  const [vouchers, setVouchers] = useState<Array<VoucherStateType>>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    const utmSource = searchParams.get('utm_source') || '';
    const utmCampaign = searchParams.get('utm_campaign') || '';
    const utmContent = searchParams.get('utm_content') || '';
    const utmMedium = searchParams.get('utm_medium') || '';

    setIsLoading(true);
    getVouchers().then((res) => {
      if (res) {
        setVouchers(res.data.giftcards);
        setIsLoading(false);
      } else {
        // Get vouchers and in case 401 error, call register account api
        registerAccount({
          utm_source: utmSource,
          utm_campaign: utmCampaign,
          utm_content: utmContent,
          utm_medium: utmMedium
        }).then((registerResponse) => {
          if (registerResponse.status === API_RESPONSE_STATUS.SUCCEEDED) {
            getVouchers().then((res) => {
              setIsLoading(false);
              setVouchers(res.data.giftcards);
            });
          }
        });
      }
    });
  }, []);
  return (
    <>
      <CommonHead title="ギフト／プリペイド一覧" />
      <div className="wrapper">
        <Container>
          {!isLoading && (
            <>
              {vouchers.length > 0 ? (
                <Stack gap={3}>
                  {vouchers.map((voucher) => (
                    <Link
                      key={voucher.giftcard_id}
                      to={`/vouchers/${voucher.giftcard_id}`}
                    >
                      <VoucherItem
                        name={voucher.name}
                        amount={voucher.balance}
                        isExpired={false}
                        image_file_url={voucher.image_file_name}
                      />
                    </Link>
                  ))}
                </Stack>
              ) : (
                <EmptyVouchers />
              )}
            </>
          )}
        </Container>
        <div className="bottom-area bg--white">
          <Link
            to="/vouchers/register"
            className="d-block btn btn--custom btn--primary btn--rounded p-3 m-3"
          >
            ギフト／プリペイドを登録する
          </Link>
          <Footer />
        </div>
      </div>
    </>
  );
};

export default VoucherList;
