import React, { useEffect, useRef, useState } from 'react';
import { useMatch } from 'react-router-dom';
import CommonHead from '../components/CommonHead';
import UseVoucherConfirm from '../components/UseVoucherConfirm';
import UseVoucherStoreInput from '../components/UseVoucherStoreInput';
import UseVoucherAmountInput from '../components/UseVoucherAmountInput';
import liff from '@line/liff/dist/lib';
import UseVoucherCompleted from '../components/UseVoucherCompleted';
import {
  getRegisteredVoucherDetailAndUsages,
  getStoreAndCheckAvailablity,
  spendVoucher
} from '../api';

const UseVoucher: React.FC = () => {
  type ParamsType = {
    amount: number;
    storeCode: string;
    storeName: string;
    availableAmount?: number | null;
  };

  type PaymentCompleteType = {
    paid_at: string;
    payment_number: string;
  };

  const initialParamValue = {
    amount: 0,
    storeCode: '',
    storeName: '',
    availableAmount: null
  };

  const initialPaymentCompleteValue = {
    paid_at: '',
    payment_number: ''
  };

  const [params, setParams] = useState<ParamsType>(initialParamValue);
  const [paymentState, setPaymentState] = useState<PaymentCompleteType>(
    initialPaymentCompleteValue
  );

  const voucherId = useMatch('/vouchers/:id/use')?.params.id ?? '';
  const [isError, setIsError] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string>('');

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [currentStep, setCurrentStep] = useState<
    'readingQr' | 'inputStoreCode' | 'inputAmount' | 'confirm' | 'completed'
  >('readingQr');
  const inputAmountRef = useRef<HTMLInputElement>(null);
  const inputStoreCodeRef = useRef<HTMLInputElement>(null);

  const currentStepTitle = {
    readingQr: 'ギフト／プリペイド利用',
    inputStoreCode: 'ギフト／プリペイド利用',
    inputAmount: 'ギフト／プリペイド利用',
    confirm: 'お支払い内容確認',
    completed: 'お支払い完了'
  };

  const verifyVoucherAndStoreCode = (
    voucherId: string,
    storeCode: string,
    from: 'qr' | 'input'
  ) => {
    return getRegisteredVoucherDetailAndUsages(voucherId).then(
      (getVoucherResponse) => {
        if (getVoucherResponse) {
          getStoreAndCheckAvailablity(voucherId, storeCode)
            .then((getStoreResponse) => {
              if (getStoreResponse.data.enable) {
                setParams((prev) => ({
                  ...prev,
                  storeCode: storeCode,
                  storeName: getStoreResponse.data.name,
                  availableAmount: getVoucherResponse.data.balance ?? 0
                }));
                setCurrentStep('inputAmount');
              } else {
                // If not available storecode, setCurrentStep as 'inputStoreCode'
                setCurrentStep('inputStoreCode');
                setIsError(true);
                setErrorMsg(
                  from === 'qr'
                    ? '読み取られた店舗コードは利用できません'
                    : '入力された店舗コードは利用できません'
                );
              }
              setIsLoading(false);
            })
            .catch((err) => {
              // If not available storecode, setCurrentStep as 'inputStoreCode'
              console.log(err);
              setCurrentStep('inputStoreCode');
              setIsError(true);
              setErrorMsg(
                from === 'qr'
                  ? '読み取られた店舗コードは利用できません'
                  : '入力された店舗コードは利用できません'
              );
              setIsLoading(false);
            });
        }
      }
    );
  };

  const handleQrReading = () => {
    setParams((prev) => ({
      ...prev,
      storeCode: ''
    }));
    setCurrentStep('readingQr');

    liff
      .scanCodeV2()
      .then((result) => {
        if (result.value === null) {
          setCurrentStep('inputStoreCode');
        } else {
          // Verify Store code and fetch store name and available amount.
          verifyVoucherAndStoreCode(voucherId, result.value ?? '', 'qr');
        }
      })
      .catch((err) => {
        console.log('UNABLE TO USE QR SCANNER', err);
        setCurrentStep('inputStoreCode');
        setIsError(true);
        setErrorMsg('QRコードを起動できませんでした');
      });
  };

  const handleSubmitStoreCodeInput = () => {
    const storeCode = inputStoreCodeRef?.current?.value;
    if (!storeCode) {
      setIsError(true);
      setErrorMsg('店舗コードを入力してください');
    } else {
      setIsError(false);
      setErrorMsg('');
      setIsLoading(true);
      //  Verify Store code and fetch store name and available amount.
      verifyVoucherAndStoreCode(voucherId, storeCode ?? '', 'input');
    }
  };

  const handleSubmitAmount = () => {
    const amount = inputAmountRef?.current?.value || '0';
    if (!inputAmountRef?.current?.value) {
      setIsError(true);
      setErrorMsg('ご利用金額を入力してください');
    } else if (parseInt(amount) <= 0) {
      setIsError(true);
      setErrorMsg('1円以上の金額を入力してください');
    } else if (parseInt(amount) > (params.availableAmount || 0)) {
      setIsError(true);
      setErrorMsg('ご利用可能額内の金額を入力してください');
    } else {
      setIsError(false);
      setErrorMsg('');
      setParams((prev) => ({
        ...prev,
        amount: parseInt(amount)
      }));
      setCurrentStep('confirm');
    }
  };

  const handleSubmitConfirmation = () => {
    // Post Spend vouchers
    setIsLoading(true);
    spendVoucher(voucherId, params.amount, params.storeCode).then((res) => {
      if (res) {
        const { paid_at, payment_number } = res.data;
        setPaymentState({
          paid_at,
          payment_number
        });
        setIsLoading(false);
        setCurrentStep('completed');
      }
    });
  };

  const handleCancelSubmitConfirmation = () => {
    setCurrentStep('inputAmount');
  };

  useEffect(() => {
    handleQrReading();
  }, []);

  return (
    <>
      <CommonHead title={currentStepTitle[currentStep]} />
      {currentStep === 'readingQr' && <div></div>}
      {currentStep === 'inputStoreCode' && (
        <UseVoucherStoreInput
          isError={isError}
          errorMsg={errorMsg}
          handleSubmitStoreCode={handleSubmitStoreCodeInput}
          handleQrReading={handleQrReading}
          ref={inputStoreCodeRef}
          isLoading={isLoading}
        />
      )}
      {currentStep === 'inputAmount' && (
        <UseVoucherAmountInput
          isError={isError}
          errorMsg={errorMsg}
          ref={inputAmountRef}
          handleSubmitAmount={handleSubmitAmount}
          handleQrReading={handleQrReading}
          voucherId={voucherId || ''}
          availableAmount={params.availableAmount}
          shop_name={params.storeName}
        />
      )}
      {currentStep === 'confirm' && (
        <UseVoucherConfirm
          shopName={params.storeName}
          amount={params.amount}
          voucherId={voucherId || ''}
          handleSubmit={handleSubmitConfirmation}
          handleCancel={handleCancelSubmitConfirmation}
          isLoading={isLoading}
        />
      )}
      {currentStep === 'completed' && (
        <UseVoucherCompleted
          shopName={params.storeName}
          usedDate={paymentState.paid_at}
          usedTime=""
          amount={params.amount}
          transId={paymentState.payment_number}
        />
      )}
    </>
  );
};

export default UseVoucher;
