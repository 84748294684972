import React from 'react';
import { Card, Stack } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import plusIcon from '../assets/icons/plus.svg';

const EmptyVouchers: React.FC = () => {
  return (
    <Link to="/vouchers/register">
      <Card className="voucher-card--empty">
        <Card.Body>
          <Stack
            direction="vertical"
            gap={3}
            className="align-items-center justify-content-center"
          >
            <div className="voucher-card__icon--empty">
              <img src={plusIcon} />
            </div>
            <div className="voucher-card__message--empty">
              <span>ギフト／プリペイドを登録しましょう</span>
            </div>
          </Stack>
        </Card.Body>
      </Card>
    </Link>
  );
};

export default EmptyVouchers;
