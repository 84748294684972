export type NewsItemType = {
  id: string;
  title: string;
  content: string;
  date: string;
  category: string;
};

export const initialNotificationValue = {
  id: '',
  title: '',
  content: '',
  date: '',
  category: ''
};
