import React from 'react';
import { Stack } from 'react-bootstrap';
import { NewsItemType } from '../types/NewsType';
import NewsItem from './NewsItem';

type NewsItemsType = {
  news: Array<NewsItemType>;
};

const NewsItems: React.FC<NewsItemsType> = (props) => {
  const { news } = props;
  return (
    <Stack className="news-list" gap={0}>
      {news.map((newsItem) => (
        <NewsItem
          key={newsItem.id}
          id={newsItem.id}
          subject={newsItem.title}
          postedDate={newsItem.date}
          category={newsItem.category}
        />
      ))}
    </Stack>
  );
};

export default NewsItems;
