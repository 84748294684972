import React from 'react';
import { Helmet } from 'react-helmet-async';

type CommonHeadType = {
  title: string;
};

const CommonHead: React.FC<CommonHeadType> = (props) => {
  const { title } = props;
  return (
    <Helmet>
      <title>{title}</title>
    </Helmet>
  );
};

export default CommonHead;
