import React from 'react';
import { Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Completed from './Completed';

const RegisterVoucherCompleted: React.FC = () => {
  return (
    <>
      <div className="d-flex vh-100">
        <Container className="my-auto">
          <Completed message="ギフト／プリペイドの登録が完了しました！" />
        </Container>
        <div className="bottom-area">
          <Link
            to="/vouchers"
            className="d-block btn btn--custom btn--primary btn--rounded p-3 m-3"
          >
            ギフト／プリペイド一覧へ
          </Link>
        </div>
      </div>
    </>
  );
};

export default RegisterVoucherCompleted;
