import React from 'react';
import { Card, Stack } from 'react-bootstrap';
import sampleImage from '../assets/images/sample_voucher.png';
import { INDEFINITE_PERIOD } from '../lib';

type VoucherItemType = {
  name: string;
  amount: number;
  isExpired: boolean;
  image_file_url: string | null;
};

const VoucherItem: React.FC<VoucherItemType> = (props) => {
  const { name, amount, image_file_url } = props;
  return (
    <Card className={`voucher-card ${amount <= 0 ? 'disabled' : ''}`}>
      <Card.Body>
        <Stack
          direction="horizontal"
          gap={3}
          className="align-items-start justify-content-between"
        >
          <div className="voucher-card__image">
            <img src={image_file_url || sampleImage} />
          </div>
          <div className="flex-grow-1">
            <div className="voucher-card__title">{name}</div>
            <div className="voucher-card__expire">
              ご利用期限：{INDEFINITE_PERIOD}
            </div>
            <div className="voucher-card__amount">
              <span className="voucher-card__amount-label">残高</span>
              <span className="voucher-card__amount-value fs-1 me-2 fw-bold">
                {amount.toLocaleString()}
              </span>
              <span className="voucher-card__amount-unit">円</span>
            </div>
          </div>
        </Stack>
      </Card.Body>
    </Card>
  );
};

export default VoucherItem;
