import React, { useEffect, useState } from 'react';
import { Container, Stack } from 'react-bootstrap';
import sampleImage from '../assets/images/sample_voucher.png';
import CommonHead from '../components/CommonHead';
import { getRegisteredVoucherDetailAndUsages } from '../api';
import { useMatch } from 'react-router-dom';
import { VoucherUsageStateType } from '../types/VoucherType';
import VoucherUsageItem from '../components/VoucherUsageItem';
import { INDEFINITE_PERIOD } from '../lib';

const initialVoucherUsageDetail: VoucherUsageStateType = {
  available_stores_url: '',
  balance: 0,
  card_no: '',
  image_file_name: null,
  name: '',
  histories: []
};

const VoucherUsagesList: React.FC = () => {
  const voucherId = useMatch('/vouchers/:id/usages')?.params.id ?? '';

  const [voucherUsageState, setVoucherUsageState] =
    useState<VoucherUsageStateType>(initialVoucherUsageDetail);

  const [isDisabled, setIsDisabled] = useState<boolean>(false);

  useEffect(() => {
    getRegisteredVoucherDetailAndUsages(voucherId).then((res) => {
      if (res) {
        const {
          available_stores_url,
          balance,
          card_no,
          image_file_name,
          name,
          histories
        } = res.data;

        if (balance <= 0) {
          setIsDisabled(true);
        }

        setVoucherUsageState({
          available_stores_url,
          balance,
          card_no,
          image_file_name,
          name,
          histories
        });
      }
    });
  }, []);

  return (
    <>
      <CommonHead title={voucherUsageState.name ?? '商品券利用履歴'} />
      <div className="wrapper bg--gray pb-5">
        <Container>
          <Stack gap={5}>
            <div className="voucher-info">
              <Stack
                direction="horizontal"
                gap={3}
                className="align-items-start justify-content-between"
              >
                <div className="voucher-info__image">
                  <img src={voucherUsageState.image_file_name || sampleImage} />
                </div>
                <div className="flex-grow-1">
                  <div className="voucher-info__name">
                    {voucherUsageState.name}
                  </div>
                  <div className="voucher-info__expire">
                    ご利用期限：{INDEFINITE_PERIOD}
                  </div>
                </div>
              </Stack>
              <div
                className={`voucher-info__amount ${
                  isDisabled ? 'disabled' : ''
                }`}
              >
                <span className="voucher-info__amount-label">残高</span>
                <span className={'voucher-info__amount-value'}>
                  {voucherUsageState.balance.toLocaleString()}
                </span>
                <span className="voucher-info__amount-unit">円</span>
              </div>
            </div>

            <Stack gap={3}>
              {voucherUsageState.histories.map((history, index) => (
                <VoucherUsageItem
                  key={index}
                  shopName={history.store_name}
                  usedDate={history.paid_at}
                  usedTime=""
                  transId={history.payment_number ?? ''}
                  amount={history.usage_amount}
                  isCharged={history.usage_amount >= 0}
                  as="usageHistory"
                />
              ))}
            </Stack>
          </Stack>
        </Container>
      </div>
    </>
  );
};

export default VoucherUsagesList;
