import React from 'react';
import { Stack } from 'react-bootstrap';
import completedIcon from '../assets/icons/check.svg';

type MessageType = {
  message: string;
};

const Completed: React.FC<MessageType> = (props) => {
  const { message } = props;
  return (
    <Stack
      gap={3}
      direction="vertical"
      className="align-items-center completed"
    >
      <div className="completed__icon">
        <img src={completedIcon} />
      </div>
      <div className="completed__message">
        <span>{message}</span>
      </div>
    </Stack>
  );
};

export default Completed;
