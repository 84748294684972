import React, { useEffect, useState } from 'react';
import { Container, Stack } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { getNotificationById } from '../api';
import CommonHead from '../components/CommonHead';
import { API_RESPONSE_STATUS } from '../lib';
import { initialNotificationValue, NewsItemType } from '../types/NewsType';

const NewsDetail: React.FC = () => {
  // for seed only.
  const { id } = useParams();

  const [notification, setNotification] = useState<NewsItemType>(
    initialNotificationValue
  );
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const { title, date, content } = notification;

  useEffect(() => {
    getNotificationById(id ?? '')
      .then((res) => {
        if (res.status === API_RESPONSE_STATUS.SUCCEEDED) {
          setNotification(res.data);
        }
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  }, []);

  return (
    <>
      <CommonHead title="詳細" />
      <div className="wrapper">
        <Container>
          {!isLoading && (
            <Stack gap={5}>
              <div className="news-header mx-2">
                <h1 className="news-header__subject">{title}</h1>
                <p className="news-header__date">{date}</p>
              </div>
              <div className="news-content">
                {content.split('\n').map((s, idx) => (
                  <p key={idx}>{s}</p>
                ))}
              </div>
            </Stack>
          )}
        </Container>
      </div>
    </>
  );
};

export default NewsDetail;
