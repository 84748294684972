import React from 'react';
import { Link, matchPath, useLocation } from 'react-router-dom';

import voucherOn from '../assets/icons/s1.svg';
import voucherOff from '../assets/icons/s3.svg';
import newsOn from '../assets/icons/s4.svg';
import newsOff from '../assets/icons/s2.svg';

const Footer: React.FC = () => {
  const { pathname } = useLocation();
  let isVoucherActive = false;
  let isNewsActive = false;

  if (matchPath('/vouchers/*', pathname)) {
    isVoucherActive = true;
  }
  if (matchPath('/news/*', pathname)) {
    isNewsActive = true;
  }

  return (
    <div className="text-center w-100 footer">
      <ul className="list-unstyled d-flex align-items-center justify-content-around m-0">
        <li>
          <Link to="/vouchers">
            <img
              src={isVoucherActive ? voucherOn : voucherOff}
              alt="ギフト／プリペイド一覧"
            />
            <span className="d-block mt-1">ギフト／プリペイド一覧</span>
          </Link>
        </li>
        <li>
          <Link to="/news">
            <img src={isNewsActive ? newsOn : newsOff} alt="お知らせ" />
            <span className="d-block mt-1">お知らせ</span>
          </Link>
        </li>
      </ul>
    </div>
  );
};

export default Footer;
