import React, { useEffect, useState } from 'react';
import { Container, Stack } from 'react-bootstrap';
import { Link, useMatch } from 'react-router-dom';
import { getRegisteredVoucherDetailAndUsages } from '../api';
import { ReactComponent as ExternalLinkIcon } from '../assets/icons/icon-link.svg';

import sampleImage from '../assets/images/sample_voucher.png';
import CommonHead from '../components/CommonHead';
import { INDEFINITE_PERIOD } from '../lib';
import { openWindow } from '../lib/openWindow';
import { VoucherDetailStateType } from '../types/VoucherType';

const initialVoucherDetail: VoucherDetailStateType = {
  available_stores_url: '',
  balance: 0,
  card_no: '',
  image_file_name: '',
  name: ''
};

const VoucherDetail: React.FC = () => {
  const voucherId = useMatch('/vouchers/:id')?.params.id ?? '';

  const [voucherDetailState, setVoucherDetailState] =
    useState<VoucherDetailStateType>(initialVoucherDetail);

  const [isDisabled, setIsDisabled] = useState<boolean>(false);

  useEffect(() => {
    getRegisteredVoucherDetailAndUsages(voucherId).then((res) => {
      if (res) {
        const {
          available_stores_url,
          balance,
          card_no,
          image_file_name,
          name
        } = res.data;

        if (balance <= 0) {
          setIsDisabled(true);
        }

        setVoucherDetailState({
          available_stores_url,
          balance,
          card_no,
          image_file_name,
          name
        });
      }
    });
  }, []);

  return (
    <>
      <CommonHead title="詳細" />

      <div className="wrapper pt-0 pb-0">
        <Stack
          direction="vertical"
          className={`voucher-detail${isDisabled ? '--disabled' : ''}`}
        >
          <Container>
            <Stack>
              <div className="voucher-detail__image">
                <img src={voucherDetailState.image_file_name ?? sampleImage} />
              </div>
              <div className="voucher-detail__info">
                <span className="voucher-detail__info-name">
                  {voucherDetailState.name}
                </span>
                <span className="voucher-detail__info-expire_date">
                  ご利用期限：{INDEFINITE_PERIOD}
                </span>
              </div>
              <div className="voucher-detail__amount">
                <span className="voucher-detail__amount-label">残高</span>
                <span className="voucher-detail__amount-value fs-1 ms-4 me-2 fw-bold">
                  {voucherDetailState.balance.toLocaleString()}
                </span>
                <span className="voucher-detail__amount-unit">円</span>
              </div>
              {!isDisabled && (
                <Link
                  to={`/vouchers/${voucherId}/use`}
                  className="d-block btn btn--custom btn--primary btn--rounded p-3 m-3"
                >
                  利用する
                </Link>
              )}
            </Stack>
          </Container>
        </Stack>

        <div className="bottom-area mb-4">
          <Stack gap={3}>
            <Link
              to={`/vouchers/${voucherId}/usages`}
              className="d-block btn btn--custom btn--secondary btn--rounded p-3 mx-3"
            >
              利用履歴
            </Link>

            <button
              onClick={() =>
                openWindow(voucherDetailState.available_stores_url)
              }
              className="d-block btn btn--custom btn--secondary btn--rounded p-3 mx-3"
            >
              <span>利用可能店舗一覧</span>
              <ExternalLinkIcon className="external-icon external-icon--secondary" />
            </button>
          </Stack>
        </div>
      </div>
    </>
  );
};

export default VoucherDetail;
